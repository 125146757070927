import React, { useState } from 'react';
import logo from './assets/logo.png';
import profileIcon from "./assets/profilepicture.png"
import './Navbar.css';

const Navbar = ({ isLoggedIn, username, isAdmin }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSignOut = () => {
    localStorage.removeItem('sessionToken');
    // Umleitung zur Login-Seite oder zu einer anderen gewünschten Seite
    window.location.href = '/login';
  };

  const renderNavbar = () => {
    if (isLoggedIn) {
      return (
        <div className="navbar">
          <div className="navbar-logo">
            <a href='/home' >
              <img src={logo} alt="Logo" className="logo" />
            </a>       
          </div>
          <div className="navbar-links">
            <button className="navbar-button" onClick={() => { window.location.href = '/createlobby'; }}>Create Lobby</button>
            <button className="navbar-button2" onClick={() => { window.location.href = '/joinlobby'; }}>Join Lobby</button>
            <button className="imprint-button" onClick={() => { window.location.href = '/imprint'; }}>Imprint</button>
          </div>
          <div className="navbar-profile" onClick={toggleDropdown}>
            <img src={profileIcon} alt="Profile" />
            {showDropdown && (
              <div className="dropdown-content">
                <p className="dropdown-username">{username}</p>
                {isAdmin ? <button className="dropdown-button" onClick={() => { window.location.href = '/usagestatistic'; }}>Statistics</button> : null}
                <button className="dropdown-button" onClick={handleSignOut}>Logout</button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="navbar">
          <div className="navbar-logo">
            <a href='/home' >
              <img src={logo} alt="Logo" className="logo" />
            </a>       
          </div>
        </div>
      );
    }
  };

  return renderNavbar();
};

export default Navbar;