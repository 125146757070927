import "../pages/Lobby.css";

const LobbyResult = ({ lobbyid, AiWords, topic, error }) => {
    return (
        <div>
            <h1 className="lobby-title">Lobby {lobbyid}</h1>
            <h2 className="lobby-topic">Topic: {topic? topic : ""}</h2>
            <h2 className="lobby-result-header"> Here is your result: </h2>
            {error ? <h2 className="lobby-topic">{error}</h2> : 
                        <div className="lobby-result-list">
                        {AiWords.map((item, index) => (
                            <div key={index}>
                                <h2 className="lobby-result-topic">{item.topic}</h2>
                                <ul className="lobby-result-word-list">
                                    {item.words.map((word, wordIndex) => (
                                        <li className="lobby-word" key={wordIndex}>{word}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                        </div>
            }
            <div className="lobby-result-buttons">
            <button className="lobby-result-button" onClick={() => window.location.href = "/home"}>Back to Home</button>
            <button className="lobby-result-button" onClick={() => window.location.href = `/createlobby`}>Create a new Lobby</button>
            </div>
        </div>
    );
}

export default LobbyResult;