import React from "react";
import Navbar from "../components/Navbar";

const Imprint = ({ isLoggedIn, username, isAdmin }) => {
    return (
      <div>
        <Navbar isLoggedIn={isLoggedIn} username={username} isAdmin={isAdmin}/>
      <div className="imprint">
        <h2>Imprint</h2>
        <p>
          Entries referred to § 5 TMG: <br />
          Maik Kraus <br />
          Wendelsteinstraße 48 <br />
          84508 Burgkirchen
        </p>
        <p>
          Contact: <br />
          Email: service@durakelite.de <br />
          Tel.: +49 1520 9426793
        </p>
      </div>
      </div>
    );
  };
  

export default Imprint;