import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import LoginSignup from './pages/LoginSignup';
import Home from './pages/Home';
import CreateLobby from './pages/CreateLobby';
import JoinLobby from './pages/JoinLobby';
import Imprint from './pages/Imprint';
import Lobby from './pages/Lobby';
import UsageStatistic from './pages/UsageStatistic';

const App = () => {

  const [isAdmin, setIsAdmin] = useState(false);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    return storedIsLoggedIn ? storedIsLoggedIn : false;
  }
  );

  const [sessionToken, setSessionToken] = useState(() => {
    const storedSessionToken = localStorage.getItem('sessionToken');
    return storedSessionToken ? storedSessionToken : "";
  });
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    localStorage.setItem('isLoggedIn', isLoggedIn);
    localStorage.setItem('sessionToken', sessionToken);
    checkSession().then((result) => setIsLoggedIn(result)); // Auf das Ergebnis von checkSession warten
    async function checkSession() {
      try {
        const response = await fetch("https://api.durakelite.de:443/api/SessionKey", {
        //const response = await fetch("https://webapi:7044/api/SessionKey", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ sessionkey: sessionToken })
        });
  
        if (!response.ok) {
          return false;
        } else {
          const data = await response.json();
          setUsername(data.username);
          setIsAdmin(data.role === "admin" ? true : false);
          return true;
        }
      } catch (error) {
        return false;
      }
    };
  }, [isLoggedIn, sessionToken]);

  

  const handleLogin = async (userData) => {
    try {
      const response = await fetch("https://api.durakelite.de:443/api/Login", {
      //const response = await fetch("https://webapi:7044/api/Login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      setSessionToken(data.sessionkey);
      setUsername(userData.username);
      setIsLoggedIn(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSignup = async (userData) => {
    try {
      const response = await fetch("https://api.durakelite.de:443/api/SignUp", {
      //const response = await fetch("https://webapi:7044/api/SignUp", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      setUsername(userData.username);
      setSessionToken(data.sessionkey);
      setIsLoggedIn(true);
    } catch (error) {
      setError(error.message);
    }
  };

  if (!isLoggedIn) {
    return (
      <Router>
        <Routes>
          <Route path="/*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginSignup onLogin={handleLogin} onSignUp={handleSignup} error={error} setError={setError} />} />
          <Route path="/imprint" element={<Imprint isLoggedIn={false} />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home username={username} isAdmin={isAdmin}/>} />
        <Route path="/createlobby" element={<CreateLobby username={username} isAdmin={isAdmin}/>} />
        <Route path="/joinlobby" element={<JoinLobby username={username} isAdmin={isAdmin}/>} />
        <Route path="/imprint" element={<Imprint isLoggedIn={true} username={username} isAdmin={isAdmin}/>} />
        <Route path="/lobby/:id" element={<LobbyComponent username={username} isAdmin={isAdmin}/>} />
        <Route path="/*" element={<Navigate to="/home" />} />
        <Route path="/usagestatistic" element={<UsageStatistic username={username} isAdmin={isAdmin}/>} />
      </Routes>
    </Router>
  );
};

const LobbyComponent = ({ username, isAdmin }) => {
  const { id } = useParams();
  return <Lobby lobbyid={id} username={username} isAdmin={isAdmin}/>;
};

export default App;

