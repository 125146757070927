import "./Pages.css";
import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';


const UsageStatistic = ({ username, isAdmin }) => {
    const [lobbiesCreated, setLobbiesCreated] = useState(0);
    const [wordsSent, setWordsSent] = useState(0);
    const [userCounter, setUserCounter] = useState(0);

    useEffect(() => {
        fetch("https://api.durakelite.de:443/api/UsageStatistics")
        .then(response => response.json())
        .then(data => {
            setLobbiesCreated(data.lobbiesCreated);
            setWordsSent(data.wordsSent);
            setUserCounter(data.userCount);
        });
    }, []);

    return (
        <div>
            <Navbar isLoggedIn={true} username={username} isAdmin={isAdmin}/>
            <div className="usage-statistic">


                { isAdmin ? 
                    <>
                        <h1>Usage Statistic</h1>
                        <table className="usage-table">
                            <thead>
                                <tr>
                                    <th className="table-header">Lobbies Created</th>
                                    <th className="table-header">Words Sent</th>
                                    <th className="table-header">User Counter</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-data">{lobbiesCreated}</td>
                                    <td className="table-data">{wordsSent}</td>
                                    <td className="table-data">{userCounter}</td>
                                </tr>
                            </tbody>
                        </table> 
                    </>
                : "You are not authorized to view this page." } 
            </div>
        </div>
    );
}


export default UsageStatistic;