import React, {useState} from 'react';
import './Pages.css'
import Navbar from '../components/Navbar';

const JoinLobby = ({ username, isAdmin }) => {

  const [lobbyid, setLobbyid] = useState("");
  const [error, setError] = useState(""); 

  const handleJoinLobby = (e) => {
    e.preventDefault();
    joinLobby();
  }

  const joinLobby = async () => {
    try {
      const response = await fetch("https://api.durakelite.de:443/api/JoinLobby", {
      //const response = await fetch("https://webapi:7044/api/JoinLobby", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ lobbyID : lobbyid})
        });
        if (!response.ok) {
          const errorData = await response.json();
          console.log(errorData.message);
          setError(errorData.message);
        } else {
          window.location.href = `/lobby/${lobbyid}`;
        }
      } catch (error) {
        console.log(error);
        console.log(error.message);
        setError(error.message);
      }
    };

    return (
        <div>
          <Navbar isLoggedIn={true} username={username} isAdmin={isAdmin}/>
          <div className='container'>
            <div className='header'>
              <div className='text'>Join Lobby</div>
              <div className='underline'></div>
            </div>
            <form onSubmit={handleJoinLobby}>
              <div className='inputs'>
                <div className='input'>
                  <input 
                    type="text"
                    placeholder='Lobby id'
                    value={lobbyid}
                    onChange={(e) => setLobbyid(e.target.value)}
                  />
                </div>
                {error && <div className='error'>{error}</div>}
              </div>
              <div className='submit-container'>
                <button className="submit" type='submit'>
                  Join Lobby
                </button>
              </div>
            </form>
          </div>
        </div>
    );
}

export default JoinLobby;