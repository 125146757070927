import React, { useState, useEffect } from 'react';
import "./Lobby.css";
import Navbar from '../components/Navbar';
import GameLobby from '../components/GameLobby';
import LobbyResult from '../components/LobbyResult';

const Lobby = ({ lobbyid, username, isAdmin }) => {
    const [inputValue, setInputValue] = useState('');
    const [words, setWords] = useState([""]);
    const [socket, setSocket] = useState(null);
    const [topic, setTopic] = useState("");
    const [lobbyCreator, setLobbyCreator] = useState("");
    const [isLobbyOpen, setIsLobbyOpen] = useState(false);
    const [aiWords, setAiWords] = useState([""]);
    const [aiWordsAreHere, setAiWordsAreHere] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Verbindung zum WebSocket-Server herstellen
        //const socket = new WebSocket('wss://webapi:8181/' + lobbyid);
        const socket = new WebSocket('wss://api.durakelite.de:443/ws/' + lobbyid);

        setSocket(socket);
        
        // Event-Handler für die WebSocket-Ereignisse
        socket.onopen = () => {
            console.log('WebSocket connection established.');
        };
  
      socket.onmessage = (event) => {
        console.log('WebSocket message received:', event.data);
        const response = JSON.parse(event.data);
        if(response.words !== undefined) {
            setWords(JSON.parse([response.words]));
        }

        if (response.topic !== undefined) {
            setTopic(response.topic);
        }
        if (response.lobbyCreator !== undefined) {
            setLobbyCreator(response.lobbyCreator);
        }
        if(response.isLobbyOpen !== undefined) {
            setIsLobbyOpen(response.isLobbyOpen);
        }
        if(response.aiWords !== undefined) {
            try {
                setAiWords(JSON.parse([response.aiWords])) 
            }
            catch (e) {
                setError(e);
            }      
        }
        if(response.areAiWordsHere !== undefined) {
            setAiWordsAreHere(response.areAiWordsHere);
        }
      };
  
      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
  
      socket.onclose = () => {
        console.log('WebSocket connection closed.');
        window.location.href = "/home";

      };
    }, [lobbyid]); // Abhängigkeit: Die WebSocket-Verbindung sollte bei Änderungen der Lobby-ID aktualisiert werden

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleAddWord = () => {
        if (inputValue.trim() !== '') {
            // Senden des neuen Worts über den WebSocket
            if (socket) {
                socket.send(inputValue.trim());
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleAddWord();
        setInputValue("");
    };

    const handleSendWords = () => {
        const sendWords = async (createLobbyData) => {
            try {
              const response = await fetch("https://api.durakelite.de:443/api/GetAiWords/", {
              //const response = await fetch("https://webapi:7044/api/GetAiWords", {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ LobbyID : lobbyid })
              });
        
              if (!response.ok) {
                console.log("Error");
              } 
            } catch (error) {
              console.log(error);
            }
          };
        sendWords();
    }

    return (
        <div>
        <Navbar isLoggedIn={true} username={username} isAdmin={isAdmin}/>
        <div className='lobby'>
            {!aiWordsAreHere ? 
            <GameLobby lobbyid={lobbyid} topic={topic} username={username} lobbyCreator={lobbyCreator} handleInputChange={handleInputChange} handleSendWords={handleSendWords} handleSubmit={handleSubmit} words={words} isLobbyOpen={isLobbyOpen} inputValue={inputValue}/>
             : 
            <LobbyResult lobbyid={lobbyid} AiWords={aiWords} topic={topic} error={error}/>}
            </div>
        </div>
    );
};

export default Lobby;