import React, { useState } from 'react'; 
import Navbar from '../components/Navbar';
import './Pages.css';

const CreateLobby = ({ username, isAdmin }) => {
  const [brainstormtopic, setBrainstormtopic] = useState("");
  const [error, setError] = useState(""); 

  const handleCreateLobby = (e) => {
    e.preventDefault();
    if (brainstormtopic.trim() === "") {
      setError("Please enter a brainstorm topic.");
    } else {
      // Generiere eine zufällige sechsstellige ID
      const randomId = Math.floor(100000 + Math.random() * 900000);
      console.log("Lobby created with ID:" +  randomId + " and topic: " + brainstormtopic);

      const createLobbyData = {
        LobbyId : randomId,
        Topic : brainstormtopic,
        Username : username
      }
      sendTopic(createLobbyData);
    }
  };

  const sendTopic = async (createLobbyData) => {
    try {
      //const response = await fetch("http://localhost:5134/api/CreateLobby", {
      const response = await fetch("https://api.durakelite.de:443/api/CreateLobby", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(createLobbyData)
      });

      if (!response.ok) {
        const data = await response.json();
        setError(data.message)
      } else {
        window.location.href = `/lobby/${createLobbyData.LobbyId}`;
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <Navbar isLoggedIn={true} username={username} isAdmin={isAdmin}/>
      <div className='container'>
        <div className='header'>
          <div className='text'>Create Lobby</div>
          <div className='underline'></div>
        </div>
        <form onSubmit={handleCreateLobby}>
          <div className='inputs'>
            <div className='input'>
              <input 
                type="text"
                placeholder='Brainstorm topic'
                value={brainstormtopic}
                onChange={(e) => setBrainstormtopic(e.target.value)}
              />
            </div>
            {error && <div className='error'>{error}</div>}
          </div>
          <div className='submit-container'>
            <button className="submit" type='submit'>
              Create Lobby
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateLobby;
