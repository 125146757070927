import "../pages/Lobby.css";


const GameLobby = ({ lobbyid, topic, words, lobbyCreator, username, inputValue, handleInputChange, handleSubmit, handleSendWords, isLobbyOpen }) => {

    return (
    <div>
    <h1 className="lobby-title">Lobby {lobbyid}</h1>
            <h2 className="lobby-topic">Topic: {topic? topic : ""}</h2>
            {!isLobbyOpen ? <h2 className="lobby-topic">Lobby closed</h2> :
            <form className="lobby-form" onSubmit={handleSubmit}>
                <input className="lobby-input" type="text" value={inputValue} onChange={handleInputChange} />
                <button className="lobby-button" type="submit">Add Word</button>
            </form> 
            }
            {words.length > 0 ? (
                <ul className="lobby-word-list">
                    {words.map((word, index) => (
                        <li className="lobby-word" key={index}>{word}</li>
                    ))}
                </ul>
            ) : (
                <ul className="lobby-word-list">
                    <li className="lobby-word">No words yet</li>
                </ul>
            )}
            {lobbyCreator === username && isLobbyOpen ? <button className="lobby-group-button" onClick={handleSendWords}>Group words with AI</button> : ""}
            </div>
    );
        }

export default GameLobby;