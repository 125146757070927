import React, { useState } from 'react';
import './Pages.css' 

import user_icon from '../components/assets/person.png'
import email_icon from '../components/assets/email.png'
import password_icon from '../components/assets/password.png'
import CookieBanner from '../components/Cookiebanner';


const LoginSignup = ({ onLogin, onSignUp, error, setError }) => {

  const [loginMode, setLoginMode] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const handleClick = () => {
    if (loginMode) {
      setLoginMode(false)
    }
    else {
      setLoginMode(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Verhindere das Standardverhalten des Formulars
    if (username.length < 4) {
      setError("Username should at least contain 4 characters");
    }
    else if (username.length > 20) {
      setError("Username should not contain more than 20 characters");
    }
    else if(password.length < 4){
      setError("Password should at least contain 4 characters");
    }
    else if (password.length > 20) { 
      setError("Password should not contain more than 20 characters");
    }
    else if(loginMode){
      const userData = {
        username: username,
        password: password
      };
          // Rufe die onLogin-Funktion auf, um den Benutzer einzuloggen
      onLogin(userData);
    } 
    else if (email.length < 4) {
      setError("Email should at least contain 4 characters");
    }
    else {
      const userData = {
        email: email,
        username: username,
        password: password
      };
          // Rufe die onLogin-Funktion auf, um den Benutzer einzuloggen
      onSignUp(userData);
    }
  };

  return (
    <div className="container"> 
      <div className="header">
        <div className="text">{loginMode ? "Login" : "Sign Up"}</div>
        <div className="underline"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="inputs">
          {!loginMode && (
            <div className="input">
              <img src={email_icon} alt=""/>
              <input 
                className='inputfield'
                type="email" 
                placeholder="Email"
                autoComplete="current-email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          )}
          <div className="input">
            <img src={user_icon} alt=""/>
            <input 
              type="text" 
              placeholder="Username"
              autoComplete="current-username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input">
            <img src={password_icon} alt=""/>
            <input 
              type="password" 
              placeholder="Password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {error && <div className="error">{error}</div>}
        </div>
        <div className="submit-container">
          <button 
            className="submit" 
            type="submit" // Submit the form
          >
            Submit
          </button>
          <button 
            className="nosubmit gray"
            onClick={handleClick}
            type="button" // Prevent form submission
          >
            {loginMode ? "Sign Up": "Login"}
          </button>
        </div> 
      </form> 
      <div className='imprint'>
      <button className='imprint-but' onClick={() => { window.location.href = '/imprint'; }}>Imprint</button>
      </div>
      <CookieBanner />
    </div>
  );
};

export default LoginSignup;
