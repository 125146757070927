import './Cookiebanner.css';
import React, { useState, useEffect } from 'react';
import Cookie01 from './assets/Cookie01.png';
import Cookie02 from './assets/Cookie02.png';
import Cookie03 from './assets/Cookie03.png';
import Cookie04 from './assets/Cookie04.png';
import Cookie05 from './assets/Cookie05.png';

const CookieBanner = () => {
  const [accepted, setAccepted] = useState(false);
  const [canAccept, setCanAccept] = useState(false);
  const [cookieImage, setCookieImage] = useState(Cookie01);
  const [animation, setAnimation] = useState(false);  

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted === 'true') {
      setAccepted(true);
    }
  }, [accepted, setAccepted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCanAccept(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleAccept = () => {
    if (canAccept) {
      setAnimation(true);
    }
  };

  useEffect(() => {
    if (animation) {
      const interval = setInterval(() => {
        if (cookieImage === Cookie01) {
          setCookieImage(Cookie02);
        } else if (cookieImage === Cookie02) {
          setCookieImage(Cookie03);
        } else if (cookieImage === Cookie03) {
          setCookieImage(Cookie04);
        } else if (cookieImage === Cookie04) {
          setCookieImage(Cookie05);
        } else {
          clearInterval(interval);
          localStorage.setItem('cookieAccepted', 'true');
          setAccepted(true);
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [accepted, cookieImage, animation]);

  return (
    <div>
      {!accepted &&(
        <div className="cookie-banner">
        <div className="cookie-banner-message">
          <img src={cookieImage} alt="Cookie" className="cookie-img" />
          <p>Our website uses cookies to ensure you get the best experience on our website.</p>
          <button className="cookie-banner-button" onClick={handleAccept} disabled={!canAccept}>
            Okay
          </button>
        </div>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;