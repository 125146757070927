import React from 'react';
import './Home.css';
import Navbar from '../components/Navbar';
import brainstormingImage from '../components/assets/full-logo.jpg';

const Home = ({ username, isAdmin }) => {
    return (
        <div>
            <Navbar isLoggedIn={true} username={username} isAdmin={isAdmin}/>
            <div className="home">
            <div className='header'>
                <h2>Welcome to BrainST</h2>
                <p>BrainST - Your Brainstorming Companion</p>
            </div>
            <img src={brainstormingImage} alt="Brainstorming" className="full-logo" />
            <div className="content">
                <div className="concept">
                    <div className="concept-details">
                        <h3>About BrainST</h3>
                        <p>BrainST is a powerful web application designed for collaborative brainstorming sessions.<br /> With its intuitive interface and advanced AI capabilities, BrainST helps teams generate creative ideas effortlessly.</p>
                    </div>
                </div>
                <div className="features">
                    <h3>Key Features</h3>
                      <li>Start and join brainstorming sessions</li>
                      <li>Live visualization of word clouds</li>
                      <li>Invite others to brainstorming sessions</li>
                      <li>AI assistant for evaluating and clustering ideas</li>
                </div>
                <div className="cta">
                    <button className="cta-button" onClick={() => { window.location.href = '/createlobby';} }>Get Started</button>
                </div>
            </div>
            </div>
        </div>
    );
};

export default Home;
